import {
    Component,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
    Output,
    TemplateRef,
    ContentChild,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'bazis-list-hint',
    templateUrl: './list-hint.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisListHintComponent {
    @HostBinding('class') get hostStyle() {
        return {
            'bazis-list': true,
            'bazis-list_control-dd': true,
        };
    }

    @Input() placeholderListKey: string;

    @Input() list;

    @Output() selected = new EventEmitter();

    @ContentChild(TemplateRef) itemTpl: TemplateRef<any>;

    selectValue(value) {
        this.selected.emit(value);
    }
}
