import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MaskSettings } from '@bazis/form/models/form-element.types';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { latinizeStr } from '@bazis/utils';
import { initialConfig } from 'ngx-mask';
import { BazisControlValueAccessor } from '@bazis/form/components/control-value-accessor.component';
import { FormControl } from '@angular/forms';
import { BazisControlFieldEndComponent } from '@bazis/form/components/parts/control-field-end.component';

@UntilDestroy()
@Component({
    selector: 'bazis-input-default',
    templateUrl: './input-default.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputDefaultComponent extends BazisControlValueAccessor {
    @Input() rows: number = 1;

    @Input() minLength: number = null;

    @Input() maxLength: number = null;

    // наличие стиралки для поля
    @Input() formatter: Function = null;

    // настройки для маски (объединила в одном поле, чтобы "не размазывать по куче настроек")
    @Input() maskSettings: MaskSettings = null;

    // convert all cyrillic symbols to latin (for vehicle gnum)
    @Input() forceLatin: boolean = false;

    @Input() type = 'text';

    // наличие внутренних отступов
    @Input() withoutInnerPadding: boolean = false;

    @Input() beforeWrite: (value: string | null) => string | null = null;

    @Input() processOnPaste: (value: string | null) => string | null; // TODO support

    // Чтобы комопненты использущий дефолтный инпут, мог обратиться к кнопке внутри компонента BazisControlFieldEndComponent
    // (например bazis-input-datetime, bazis-input-timeinterval)
    @ViewChild('fieldEnd') fieldEnd: BazisControlFieldEndComponent;

    public field = new FormControl('');

    valueChanges$ = this.field.valueChanges.pipe(
        debounceTime(0),
        tap((value) => {
            if (this.formatter || (this.forceLatin && value)) {
                let newValue = this.forceLatin
                    ? latinizeStr(value.toUpperCase())
                    : this.formatter(value);
                if (value !== newValue) {
                    this.field.setValue(newValue);
                    return;
                }
            }
            this.onChange(value);
        }),
    );

    statusChanges$ = this.field.statusChanges.pipe(
        debounceTime(0),
        tap((status) => {
            if (status === 'INVALID') {
                this.ngControl.control.setErrors({
                    ...this.ngControl.control.errors,
                    componentError: true,
                });
            } else {
                const errors = this.ngControl.control.errors;
                if (errors) {
                    delete errors.componentError;
                    this.ngControl.control.setErrors({ ...errors });
                }
            }
        }),
    );

    public writeValue(value: any): void {
        if (!this.ngControl.control) return;

        if (this.beforeWrite) value = this.beforeWrite(value);
        this.field.setValue(value, { emitEvent: false });
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.field.disable();
        } else {
            this.field.enable();
        }
    }

    extendOnInit() {
        if (this.maskSettings) {
            this.maskSettings = {
                ...initialConfig,
                ...this.maskSettings,
            };
        }
        this.canEraserShow = !this.field.disabled && this.hasEraser && this.rows === 1;
    }
}
