<ng-container *transloco="let t">
    <div
        *ngIf="ngControl.control"
        [class]="'bazis-control ' + cssClassSizeControl"
        [ngClass]="{
            'bazis-control--focused':isFocused,
            'bazis-control--dirty': ngControl.control.value,
            'bazis-control--invalid': ngControl.control.invalid,
            'bazis-control--disabled': field.disabled
        }"
    >
        <ng-container
            *ngIf="{
                required: required$ | async,
                valueChanges: valueChanges$ | async
            } as data"
        >
            <bazis-label
                [isMajor]="hasTitleMajor"
                [isEmptyLabel]="isEmptyLabel"
                [title]="title || t(titleKey, titleParams)"
                [required]="data.required"
            >
                <bazis-interrogation
                    *ngIf="tooltipKey"
                    [slot]="tooltipSettings?.targetSide"
                    iconSize="xs"
                    [tooltipText]="t(tooltipKey, tooltipParams)"
                    [tooltipOffset]="[-8, 4]"
                    [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                    [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                    [icon]="icons.interrogation"
                    [type]="interrogationType"
                ></bazis-interrogation>
            </bazis-label>

            <div [class.bazis-control__field-group-wrapper]="hasStepButton">
                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--disabled]="field.disabled"
                    [class.bazis-control__field-group--focused]="isFocused"
                >
                    <bazis-control-field-start
                        [icon]="leftIcon"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                    >
                    </bazis-control-field-start>

                    <input
                        type="number"
                        class="bazis-control__field"
                        [class.bazis-control__field--erasable]="hasEraser"
                        [formControl]="field"
                        [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                        (focus)="focusField($event)"
                        (blur)="blurField()"
                        (keydown)="onKeyDown($event)"
                    />

                    <bazis-control-field-end
                        [isClickable]="isRightIconClickable"
                        [icon]="rightIcon"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        [unitText]="unit || t(unitKey)"
                        [canEraserShow]="canEraserShow"
                        [value]="ngControl.control.value"
                        (erased)="onClear($event)"
                        (actionClick)="onRightIconClick($event)"
                    >
                    </bazis-control-field-end>
                </div>

                <bazis-button
                    *ngIf="increment && hasStepButton && !field.disabled"
                    fill="outline"
                    color="action"
                    [disabled]="
                        minNumber !== undefined &&
                        minNumber !== null &&
                        (field.value || 0) - increment < minNumber
                    "
                    (click)="addToValue(-increment)"
                >
                    <bazis-icon
                        slot="icon-only"
                        [name]="icons.minus"
                    ></bazis-icon>
                </bazis-button>
                <bazis-button
                    *ngIf="increment && hasStepButton && !field.disabled"
                    fill="outline"
                    color="action"
                    [disabled]="
                        maxNumber !== undefined &&
                        maxNumber !== null &&
                        (field.value || 0) + increment > maxNumber
                    "
                    (click)="addToValue(+increment)"
                >
                    <bazis-icon
                        slot="icon-only"
                        [name]="icons.plus"
                    ></bazis-icon>
                </bazis-button>
            </div>

            <div class="bazis-control__error">
                <bazis-form-error
                    [formControl]="ngControl.control"
                    [validationErrorMessages]="validationErrorMessages"
                ></bazis-form-error>
                <ng-content></ng-content>
            </div>

            <div
                *ngIf="noteKey"
                class="bazis-control__note"
            >
                {{ t(noteKey, noteParams) }}
            </div>

            <ng-content select="note"></ng-content>
        </ng-container>
    </div>
</ng-container>
