<div
    *transloco="let t"
    class="sl-symbol-control bazis-control"
>
    <ng-container
        *ngIf="{
            required: required$ | async,
            valueChanges: valueChanges$ | async
        } as data"
    >
        <bazis-label
            [isMajor]="hasTitleMajor"
            [isEmptyLabel]="isEmptyLabel"
            [title]="title || t(titleKey, titleParams)"
            [required]="data.required"
        >
            <bazis-interrogation
                *ngIf="tooltipKey"
                [slot]="tooltipSettings?.targetSide"
                iconSize="xs"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipOffset]="[-8, 4]"
                [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
            ></bazis-interrogation>
        </bazis-label>

        <div class="bh-flex bh-align-items-center">
            <bazis-control-field-start
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipSettings]="tooltipSettings"
            >
            </bazis-control-field-start>
            <div
                *ngIf="fields.controls?.length > 0"
                #group
                class="bazis-control__field-group-wrapper"
            >
                <span
                    *ngFor="let field of fields.controls; let i = index"
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--disabled]="fields.disabled"
                    [class.bazis-control__field-group--focused]="isFocused"
                >
                    <input
                        [type]="type"
                        class="bazis-control__field"
                        (input)="onInput(i)"
                        (keyup.backspace)="onBackspace(i)"
                        [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                        (focus)="onFocus(i)"
                        [formControl]="field"
                    />
                </span>
            </div>
            <bazis-control-field-end
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipSettings]="tooltipSettings"
            >
            </bazis-control-field-end>
        </div>

        <div class="bazis-control__error">
            <bazis-form-error
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>

            <!-- для ошибок родительского контрола
            (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
            <ng-content></ng-content>
        </div>

        <div
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            {{ t(noteKey, noteParams) }}
        </div>
    </ng-container>
</div>
