<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            list: list$ | async,
            municipality: municipality$ | async,
            valueChanges: valueChanges$ | async
        } as data"
    >
        <bazis-label
            [isMajor]="hasTitleMajor"
            [isEmptyLabel]="isEmptyLabel"
            [title]="title || t(titleKey, titleParams)"
            [required]="data.required"
        >
            <bazis-interrogation
                *ngIf="tooltipKey"
                [slot]="tooltipSettings?.targetSide"
                iconSize="xs"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipOffset]="[-8, 4]"
                [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                [icon]="icons.interrogation"
                [type]="interrogationType"
            ></bazis-interrogation>
        </bazis-label>
        <div
            *ngIf="rows === 1 && !isVisibleField && ngControl.value"
            class="bh-hide-sm-up bh-margin-bottom-6x"
        >
            <p class="bh-margin-vertical-2x">{{ ngControl.value }}</p>
            <bazis-link
                class="sl-letter-spacing_025"
                borderStyle="none"
                (click)="showField($event)"
                >{{ t('action.change') }}</bazis-link
            >
        </div>
        <div
            class="bazis-list_control-dd-wrapper"
            [class.bh-events-none]="field.disabled"
            [class.bh-hide-sm-down]="!isVisibleField"
            clickOutside
            (clickOutside)="hideList()"
        >
            <div
                class="bazis-control"
                [class.bazis-control--focused]="isFocused"
                [class.bazis-control--dirty]="ngControl.control.value"
            >
                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--disabled]="field.disabled"
                    [class.bazis-control__field-group--focused]="isFocused"
                >
                    <bazis-control-field-start
                        [isClickable]="isLeftIconClickable"
                        [icon]="leftIcon"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        (actionClick)="onLeftIconClick($event)"
                    >
                    </bazis-control-field-start>

                    <div
                        class="bazis-control__field-wrapper"
                        #fieldWrapRef
                    >
                        <input
                            class="bazis-control__field"
                            [class.bh-no-padding]="withoutInnerPadding"
                            *ngIf="rows === 1"
                            [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                            (focus)="focusField($event)"
                            (blur)="blurField($event)"
                            [formControl]="field"
                        />
                        <textarea
                            class="bazis-control__field"
                            *ngIf="rows > 1"
                            [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                            (focus)="focusField($event)"
                            (blur)="blurField($event)"
                            [formControl]="field"
                        ></textarea>
                    </div>

                    <bazis-control-field-end
                        [isClickable]="isRightIconClickable"
                        [icon]="rightIcon"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        [unitText]="unit || t(unitKey)"
                        [canEraserShow]="canEraserShow"
                        [value]="ngControl.control.value"
                        (actionClick)="onRightIconClick($event)"
                        (erased)="onClear($event)"
                    >
                    </bazis-control-field-end>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>

                    <!-- для ошибок родительского контрола
                (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
                    <ng-content></ng-content>
                </div>

                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ t(noteKey, noteParams) }}
                </div>

                <bazis-list-hint
                    *ngIf="data.list && data.list.length > 0 && showList"
                    [list]="data.list"
                    (selected)="selectFromList($event)"
                >
                    <ng-template let-item="item">{{ item.address }}</ng-template>
                </bazis-list-hint>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="listFromLatLng$ | async"></ng-container>
