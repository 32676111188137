<div *transloco="let t" #reference>
    <bazis-button
        (click)="geolocate()"
        fill="outline"
    >
        <bazis-icon
            slot="icon-only"
            name="marker"
        ></bazis-icon>
    </bazis-button>
    <bazis-tooltip
        [reference]="reference"
        placement="left"
        class="bh-popper-z-index"
        [isStatic]="true"
        [offset]="[-12, 0]"
        [arrow]="false"
        >{{ t('map.tooltip.myLocation') }}</bazis-tooltip
    >
</div>
