import {
    Component,
    ChangeDetectionStrategy,
    Input,
    TemplateRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-multiple-selection-item',
    templateUrl: './multiple-selection-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisMultipleSelectionItemComponent {
    @Input() item;

    @Input() multiple: boolean = false;

    @Input() infiniteListTpl: TemplateRef<any>;

    @Input() fieldForId: string;

    @Input() valuesMap: Map<any, boolean>;

    @Output() toggled = new EventEmitter();

    constructor(public mqSrv: BazisMediaQueryService) {}

    toggleSelectedValue(e) {
        this.toggled.emit(e);
    }
}
