import moment from 'moment/moment';

export const getDisabledWeekends = () => {
    const intervals = [];
    const startOfCurrentWeek = moment().startOf('isoWeek');

    for (let i = 0; i < 8; i++) {
        const saturday = startOfCurrentWeek.clone().add(i, 'weeks').isoWeekday(6);
        const sunday = startOfCurrentWeek.clone().add(i, 'weeks').isoWeekday(7);

        intervals.push([saturday.format('YYYY-MM-DD'), sunday.format('YYYY-MM-DD')]);
    }

    return intervals;
};
