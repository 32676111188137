<ng-container
    *ngIf="{
        required: required$ | async,
        onMoveTo: onMoveTo$ | async
    } as data"
>
    <bazis-grid
        class="bazis-grid_form bazis-map-point-control bazis-control"
        *transloco="let t"
    >
        <bazis-row *ngIf="titleKey">
            <bazis-col>
                <h6>
                    {{ t(titleKey, titleParams) }}
                    <span
                        color="danger"
                        *ngIf="data.required"
                    >
                        *
                    </span>
                    <ng-container *ngIf="tooltipKey">
                        <span #tooltipRef>
                            <bazis-icon [name]="icons.interrogation"></bazis-icon>
                            <bazis-tooltip
                                [reference]="tooltipRef"
                                placement="bottom-start"
                                [isStatic]="true"
                            >
                                {{ t(tooltipKey, tooltipParams) }}
                            </bazis-tooltip>
                        </span>
                    </ng-container>
                </h6>
            </bazis-col>
        </bazis-row>
        <ng-container *ngIf="ready.$ | async">
            <bazis-row>
                <bazis-col size="12">
                    <div
                        class="bazis-map-wrapper"
                        [class.bh-no-margin]="hideCoordinateFields"
                    >
                        <bazis-map
                            [mapSettings$]="mapSettings.$"
                            [mapLayers$]="layerSettings.$"
                            [centerMapOnCoordinates$]="centerMapOnCoordinates.$"
                            (dragged)="move($event)"
                        ></bazis-map>
                    </div>
                </bazis-col>
            </bazis-row>
            <bazis-row *ngIf="ngControl.control.value && showCoordinatesValue">
                <bazis-col>
                    <bazis-coordinates [point]="ngControl.control.value"></bazis-coordinates>
                </bazis-col>
            </bazis-row>
            <bazis-row *ngIf="!hideCoordinateFields">
                <bazis-col
                    sizeSm="6"
                    sizeXs="12"
                >
                    <bazis-input-number
                        [formControl]="lat"
                        [titleKey]="latTitleKey"
                        [required$]="required$"
                    ></bazis-input-number>
                </bazis-col>
                <bazis-col
                    sizeSm="6"
                    sizeXs="12"
                >
                    <bazis-input-number
                        [titleKey]="lngTitleKey"
                        [formControl]="lng"
                        [required$]="required$"
                    ></bazis-input-number>
                </bazis-col>
            </bazis-row>
        </ng-container>
        <bazis-row class="bazis-control__error">
            <bazis-col>
                <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
            </bazis-col>
        </bazis-row>
        <bazis-row
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            <bazis-col>
                {{ t(noteKey, noteParams) }}
            </bazis-col>
        </bazis-row>
    </bazis-grid>
</ng-container>
