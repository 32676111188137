<ng-container *transloco="let t">
    <div
        *ngIf="placeholderListKey"
        class="bazis-item bazis-item_placeholder"
    >
        {{ t(placeholderListKey) }}
    </div>
    <div
        *ngFor="let item of list"
        class="bazis-item"
        (click)="selectValue(item)"
    >
        <ng-template
            [ngTemplateOutlet]="itemTpl"
            [ngTemplateOutletContext]="{ item: item }"
        ></ng-template>
    </div>
</ng-container>
