<ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipText">
    <bazis-interrogation
        iconSize="xs"
        [tooltipText]="tooltipText"
        [tooltipOffset]="[8, 4]"
        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
        [tooltipPlacement]="tooltipSettings?.placement || 'bottom-end'"
        [icon]="icons.interrogation"
        [type]="interrogationType"
    ></bazis-interrogation>
</ng-container>
<bazis-button
    *ngIf="
        (searchValue || (controlValue && !multiple) || (multiple && controlValue?.length)) &&
        !isDisabled &&
        canEraserShow
    "
    fill="clear"
    (click)="onClear($event)"
    class="bazis-eraser"
>
    <bazis-icon
        slot="icon-only"
        size="s"
        [name]="icons.eraser"
    ></bazis-icon>
</bazis-button>
<bazis-button
    *ngIf="hasSearchIcon"
    fill="clear"
    (click)="toggleList()"
>
    <bazis-icon
        size="s"
        [name]="icons.search"
    ></bazis-icon>
</bazis-button>

<bazis-button
    *ngIf="otherIcon"
    fill="clear"
    (click)="toggleList()"
>
    <bazis-icon
        size="s"
        [icon]="otherIcon"
    ></bazis-icon>
</bazis-button>

<ng-container *ngIf="!hasSearchIcon && !otherIcon">
    <!-- временно, пока со всеми выпадашками не разберемся по адаптиву -->
    <button
        (click)="toggleList()"
    >
        <bazis-icon
            [name]="showList ? iconUp : iconDown"
            size="s"
        ></bazis-icon>
    </button>
    <!-- <button
        *ngIf="mqSrv.matchBreakpoint('lg')"
        (click)="toggleList()"
    >
        <bazis-icon
            [name]="showList ? iconUp : iconDown"
            size="s"
        ></bazis-icon>
    </button>
    <button
        *ngIf="showList && mqSrv.inIntervalBreakpoint('xs', 'lg')"
        tabindex="-1"
        (click)="onHideListMobile()"
    >
        <bazis-icon
            [name]="icons.close"
            size="s"
        ></bazis-icon>
    </button> -->
</ng-container>
