<ng-container *ngIf="unitText || icon || (value && canEraserShow)">
    <bazis-button
        *ngIf="value && canEraserShow"
        fill="clear"
        (click)="onClear($event)"
        class="bazis-eraser"
    >
        <bazis-icon
            slot="icon-only"
            [name]="icons.eraser"
        ></bazis-icon>
    </bazis-button>
    <span
        *ngIf="unitText"
        class="bazis-control__units"
        [innerHTML]="unitText"
    ></span>
    <ng-container *ngIf="icon">
        <bazis-button
            *ngIf="isClickable && !readonly"
            fill="clear"
            #rightBtn
            (click)="onClick($event)"
        >
            <bazis-icon
                slot="icon-only"
                [icon]="icon"
            ></bazis-icon>
        </bazis-button>
        <bazis-icon
            *ngIf="!isClickable"
            [icon]="icon"
        ></bazis-icon>
    </ng-container>
</ng-container>
<ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipText">
    <bazis-interrogation
        iconSize="xs"
        [tooltipText]="tooltipText"
        [tooltipOffset]="[8, 4]"
        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
        [tooltipPlacement]="tooltipSettings?.placement || 'bottom-end'"
        [icon]="icons.interrogation"
        [type]="interrogationType"
    ></bazis-interrogation>
</ng-container>
