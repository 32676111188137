import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    OnInit,
    HostBinding,
} from '@angular/core';
import {
    LatLng,
    MapDraggedEvent,
    MapLayers,
    MapPopupInfo,
    MapSettings,
} from '@bazis/map/models/map.models';
import { BehaviorSubject, combineLatest, Observable, shareReplay } from 'rxjs';
import { BazisMapService } from '@bazis/map/services/map.service';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import * as L from 'leaflet';

@Component({
    selector: 'bazis-map',
    templateUrl: 'map.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BazisMapService],
})
export class BazisMapComponent implements OnInit {
    @HostBinding('class') get getMapClass() {
        return {
            'bazis-map_fullscreen': this.isFullscreen$.value,
        };
    }

    @Input()
    tileMode: 'default' | 'grey' = 'default';

    @Input()
    mapSettings$: Observable<MapSettings>;

    @Input()
    mapLayers$: Observable<MapLayers>;

    @Input()
    selectOnMap$: Observable<{ layerId: string; objectId: string; openPopup?: boolean }>;

    @Input()
    popupInfo$: Observable<MapPopupInfo>;

    @Input()
    fitLayersBounds$: Observable<{ layers: string[]; maxZoom?: number }>;

    @Input()
    containerResized$: Observable<boolean>;

    @Input()
    centerMapOnCoordinates$: Observable<{
        lat: number;
        lng: number;
        minZoom?: number;
        animationDuration?: number;
    }>;

    @Input()
    openModalControlEnabled = false;

    @Input()
    tileControlEnabled = true;

    @Input()
    zoomControlEnabled = true;

    @Input()
    myLocationEnabled = true;

    @Input()
    startAsFullScreen = false;

    @Input()
    fullscreenControlEnabled = false;

    @Output()
    selected = new EventEmitter();

    @Output()
    popupAction = new EventEmitter();

    @Output()
    dragged = new EventEmitter();

    @Output()
    fullScreenActivated: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('mapElement', { static: true }) mapElement: ElementRef;

    settings$: Observable<MapSettings>;

    resized$: Observable<boolean>;

    select$: Observable<{ layerId: string; objectId: string }>;

    centerOnCoordinates$: Observable<LatLng>;

    layers$: Observable<MapLayers>;

    map$: Observable<L.Map>;

    selectedTileId = '';

    mapSelected$ = this.mapService.selected.$.pipe(
        tap((options) => {
            console.log('next selected on map', options);
            this.selected.emit(options);
        }),
        shareReplay(),
    );

    popupActions$ = this.mapService.popupActions.$.pipe(
        tap((popupAction) => {
            console.log('action from popup', popupAction);
            this.popupAction.emit(popupAction);
        }),
    );

    dragged$ = this.mapService.dragged.$.pipe(
        tap((e: MapDraggedEvent) => {
            this.dragged.emit(e);
        }),
    );

    executeFitLayersBounds$;

    isFullscreen$ = new BehaviorSubject(false);

    constructor(public mapService: BazisMapService) {}

    ngOnInit() {
        this.settings$ = this.mapSettings$.pipe(
            tap((options) => {
                this.mapService.initMap(this.mapElement.nativeElement, options);
                this.selectedTileId = this.mapService.getInitialTileSettings(options).id;
            }),
            shareReplay(),
        );

        this.layers$ = this.mapLayers$.pipe(
            debounceTime(2),
            tap((layerGroups) => {
                this.mapService.setLayers(layerGroups);
            }),
            shareReplay(),
        );

        this.select$ = this.selectOnMap$?.pipe(
            tap((params) => {
                if (params) {
                    this.mapService.selectById(params.layerId, params.objectId, params.openPopup);
                }
            }),
            shareReplay(),
        );

        this.centerOnCoordinates$ = this.centerMapOnCoordinates$?.pipe(
            filter((v) => !!v),
            tap((params) => {
                if (params.animationDuration === undefined) {
                    params.animationDuration = 0.35;
                }
                this.mapService.centerOnCoordinates(
                    params.lat,
                    params.lng,
                    params.minZoom,
                    params.animationDuration,
                );
            }),
            shareReplay(),
        );

        this.map$ = this.settings$.pipe(map(() => this.mapService.map));

        if (!this.containerResized$) {
            this.containerResized$ = new BehaviorSubject(false);
        }

        this.resized$ = combineLatest([this.containerResized$, this.map$, this.isFullscreen$]).pipe(
            debounceTime(0),
            map((v) => {
                if (this.mapService.map) this.mapService.map.invalidateSize();
                return true;
            }),
        );

        if (this.fitLayersBounds$) {
            this.executeFitLayersBounds$ = this.fitLayersBounds$.pipe(
                tap((fitBoundsSettings) => {
                    this.mapService.fitLayersBounds(
                        fitBoundsSettings.layers,
                        fitBoundsSettings.maxZoom || 7,
                    );
                }),
            );
        }

        if (this.fullscreenControlEnabled && this.startAsFullScreen) {
            this.setFullscreen(true);
        }
    }

    setTileLayer(tile) {
        this.mapService.setTileLayer(tile);
        this.selectedTileId = tile.id;
    }

    setFullscreen(value) {
        this.isFullscreen$.next(value);
        this.fullScreenActivated.emit(value);
    }
}
