import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BazisControlValueAccessor } from '@bazis/form/components/control-value-accessor.component';

@UntilDestroy()
@Component({
    selector: 'bazis-input-number',
    templateUrl: './input-number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputNumberComponent extends BazisControlValueAccessor {
    @Input() minNumber: number = null;

    @Input() maxNumber: number = null;

    @Input() increment: number = null;

    @Input() hasStepButton: boolean = false;

    @Input() precision = 12;

    @Input() forbiddenSymbols: string[] = ['e', 'E'];

    field = new FormControl<number | null>(null);

    valueChanges$ = this.field.valueChanges.pipe(
        tap((value) => {
            let isFailValue = isNaN(value) || value === undefined;
            let formattedValue = this._formatValue(value);
            this.onChange(isFailValue || formattedValue !== value ? null : value);
        }),
    );

    extendOnInit() {
        this.canEraserShow = !this.field.disabled && this.hasEraser;

        // writeValue в инит отсутствует
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.field.disable();
        } else {
            this.field.enable();
        }
    }

    public writeValue(value: any): void {
        const newValue = value === '' || value === undefined || value === null ? null : +value;
        this.field.setValue(newValue, { emitEvent: false });
    }

    addToValue(incrementValue) {
        const newValue = (+this.field.value || 0) + incrementValue;
        this.field.setValue(this._formatValue(newValue));
    }

    blurField() {
        this.isFocused = false;

        if (this.field.value === null) return;
        const formattedValue = this._formatValue(this.field.value);
        if (this.field.value !== formattedValue) this.field.setValue(formattedValue);

        this.blured.emit();
    }

    protected _formatValue(newValue) {
        if (newValue === null || newValue === '') return null;
        if (this.maxNumber !== null && this.maxNumber !== undefined && newValue > this.maxNumber) {
            newValue = this.maxNumber;
        }
        if (this.minNumber !== null && this.minNumber !== undefined && newValue < this.minNumber) {
            newValue = this.minNumber;
        }

        return +parseFloat(newValue).toFixed(this.precision);
    }

    onClear(e) {
        if (this.canEraserShow) {
            this.erased.emit(e);
        }
        this.ngControl.control.setValue(null);
    }

    onKeyDown(e) {
        if (!this.forbiddenSymbols) return;
        if (this.forbiddenSymbols.includes(e.key)) e.preventDefault();
    }
}
