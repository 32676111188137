import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { TooltipSettings } from '@bazis/form/models/form-element.types';

@Component({
    selector: 'bazis-control-field-end',
    templateUrl: './control-field-end.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisControlFieldEndComponent {
    @HostBinding('class') get hostClass() {
        return {
            'bazis-control__field-group__end':
                this.unitText ||
                this.icon ||
                (this.value && this.canEraserShow) ||
                (this.tooltipSettings?.targetSide === 'right' && this.tooltipText),
            'bh-contents':
                !this.unitText &&
                !this.icon &&
                !(this.value && this.canEraserShow) &&
                this.tooltipSettings?.targetSide !== 'right',
        };
    }

    @Input() icon: string = null;

    @Input() isClickable: boolean = null;

    @Input() readonly: boolean = null;

    @Input() tooltipText: string = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() unitText: string = null;

    @Input() canEraserShow: boolean = null;

    @Input() value;

    // событие по клику на иконке
    @Output() actionClick = new EventEmitter();

    @Output() erased = new EventEmitter();

    @ViewChild('rightBtn') rightBtn: ElementRef;

    icons;

    // светлая или темная тема подсказки по иконке
    interrogationType: 'dark' | 'light' = this.configurationService.controlInterrogationType;

    constructor(private configurationService: BazisConfigurationService) {
        this.icons = this.configurationService.controlIcons;
    }

    onClear(e) {
        this.erased.emit(e);
    }

    onClick(e) {
        this.actionClick.emit(e);
    }
}
