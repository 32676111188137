<div
    *transloco="let t"
    class="bazis-item"
    [ngClass]="{
        'bazis-item--selected':
            valuesMap &&
            valuesMap.has(
                fieldForId && item.$snapshot[fieldForId]?.id
                    ? item.$snapshot[fieldForId]?.id
                    : item.id
            ),
        'bazis-checkbox': multiple,
        'bazis-radio': mqSrv.inIntervalBreakpoint('xs', 'lg') && !multiple
    }"
    (click)="toggleSelectedValue($event)"
>
    <span
        [ngClass]="{
            'bazis-checkbox__label': multiple,
            'bazis-radio__label': mqSrv.inIntervalBreakpoint('xs', 'lg') && !multiple
        }"
    >
        <ng-container
            [ngTemplateOutlet]="infiniteListTpl"
            [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
        <ng-container *ngIf="!infiniteListTpl">
            {{ item.$snapshot.name || t(item.$snapshot.nameKey, item.$snapshot.nameParams) }}
        </ng-container>
    </span>
</div>
