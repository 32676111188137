<ng-container
    *ngIf="infiniteListTpl && !selectedValueTpl"
    [ngTemplateOutlet]="infiniteListTpl"
    [ngTemplateOutletContext]="{ item: entity }"
></ng-container>

<ng-container
    *ngIf="selectedValueTpl"
    [ngTemplateOutlet]="selectedValueTpl"
    [ngTemplateOutletContext]="{ item: entity }"
></ng-container>

<ng-container *ngIf="!infiniteListTpl">
    <!-- <ng-container *ngIf="entity.$snapshot.name">
        {{ entity.$snapshot.name }}
    </ng-container>
    <ng-container *ngIf="entity.$snapshot.nameKey">
        <ng-container *transloco="let t">
            {{ t(entity.$snapshot.nameKey, entity.$snapshot.nameParams) }}
        </ng-container>
    </ng-container> -->

    <ng-container *transloco="let t">
        {{
            entity.$snapshot.name ||
                entity.$snapshot[entityNameField] ||
                t(entity.$snapshot.nameKey, entity.$snapshot.nameParams)
        }}
    </ng-container>
</ng-container>
{{ index < valuesLength - 1 ? ',' : '' }}
