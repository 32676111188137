<div
    *transloco="let t"
    [class]="'bazis-control ' + cssClassSizeControl"
    [ngClass]="{
        'bazis-control--focused':isFocused,
        'bazis-control--dirty': ngControl.control.value,
        'bazis-control--invalid': ngControl.control.invalid,
        'bazis-control--disabled': field.disabled
    }"
>
    <ng-container
        *ngIf="{
            required: required$ | async,
            valueChanges: valueChanges$ | async
        } as data"
    >
        <bazis-label
            [isMajor]="hasTitleMajor"
            [isEmptyLabel]="isEmptyLabel"
            [title]="title || t(titleKey, titleParams)"
            [required]="data.required"
        >
            <bazis-interrogation
                *ngIf="tooltipKey"
                [slot]="tooltipSettings?.targetSide"
                iconSize="xs"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipOffset]="[-8, 4]"
                [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                [icon]="icons.interrogation"
                [type]="interrogationType"
            ></bazis-interrogation>
        </bazis-label>

        <div
            class="bazis-control__field-group"
            [class.bazis-control__field-group--disabled]="field.disabled"
            [class.bazis-control__field-group--focused]="isFocused"
        >
            <bazis-control-field-start
                [isClickable]="isLeftIconClickable"
                [icon]="leftIcon"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipSettings]="tooltipSettings"
                (actionClick)="onLeftIconClick($event)"
            >
            </bazis-control-field-start>

            <div class="bazis-control__field-wrapper">
                <input
                    [type]="showPass ? 'text' : 'password'"
                    class="bazis-control__field"
                    [class.bh-no-padding]="withoutInnerPadding"
                    [placeholder]="placeholder ? placeholder : t(placeholderKey)"
                    (focus)="markAsTouched(); isFocused = true"
                    (blur)="blurField($event)"
                    [autocomplete]="autocomplete"
                    [formControl]="field"
                />
            </div>

            <bazis-control-field-end
                [isClickable]="true"
                [icon]="showPass ? icons.hidePass : icons.showPass"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipSettings]="tooltipSettings"
                [unitText]="unit || t(unitKey)"
                [canEraserShow]="canEraserShow"
                [value]="ngControl.control.value"
                (actionClick)="showPass = !showPass"
                (erased)="onClear($event)"
            >
            </bazis-control-field-end>
        </div>

        <div class="bazis-control__error">
            <bazis-form-error
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>

            <!-- для ошибок родительского контрола
            (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
            <ng-content></ng-content>
        </div>

        <div
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            {{ t(noteKey, noteParams) }}
        </div>
    </ng-container>
</div>
