<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            values: values.$ | async
        } as data"
    >
        <div
            [class]="'bazis-control bazis-control_autocomplete ' + cssClassSizeControl"
            [ngClass]="{
                'bazis-control--activated': showList,
                'bazis-control--dirty': ngControl.control.value,
                'bazis-control--invalid': ngControl.control.invalid,
                'bazis-control--disabled': isDisabled
            }"
        >
            <bazis-label
                [isMajor]="hasTitleMajor"
                [isEmptyLabel]="isEmptyLabel"
                [title]="title || t(titleKey, titleParams)"
                [required]="data.required"
            >
                <bazis-interrogation
                    *ngIf="tooltipKey"
                    [slot]="tooltipSettings?.targetSide"
                    iconSize="xs"
                    [tooltipText]="t(tooltipKey, tooltipParams)"
                    [tooltipOffset]="[-8, 4]"
                    [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                    [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                    [icon]="icons.interrogation"
                    [type]="interrogationType"
                ></bazis-interrogation>
            </bazis-label>

            <div
                *ngIf="viewTypeSelected === 'tagsStart'"
                class="bazis-tags-group"
            >
                <ng-container [ngTemplateOutlet]="tplTags"></ng-container>
            </div>

            <!-- Мобильная версия требует доработки -->
            <div
                *ngIf="!isVisibleField && ngControl.value"
                class="bh-hide-sm-up bh-margin-bottom-6x"
            >
                <p class="bh-margin-vertical-2x">
                    <ng-container [ngTemplateOutlet]="tplSelectTrigger"></ng-container>
                </p>
                <bazis-link
                    class="sl-letter-spacing_025"
                    borderStyle="none"
                    (click)="showField($event)"
                    >{{ t('action.change') }}</bazis-link
                >
            </div>

            <div
                #wrapper
                class="bazis-list_control-dd-wrapper"
                [class.bh-events-none]="isDisabled"
                clickOutside
                (clickOutside)="hideList()"
            >
                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--activated]="showList"
                    [class.bazis-control__field-group--disabled]="isDisabled"
                    [class.bh-hide-sm-down]="!isVisibleField"
                >
                    <bazis-control-field-start
                        [isClickable]="isLeftIconClickable"
                        [icon]="leftIcon"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        (actionClick)="onLeftIconClick($event)"
                    >
                    </bazis-control-field-start>

                    <div class="bazis-control__field">
                        <!-- Прекрасный кастомный селект -->
                        <div
                            class="bazis-select"
                            [class.bazis-select_secondary]="type === 'secondary'"
                            (click)="toggleList()"
                        >
                            <div
                                *ngIf="viewTypeSelected === 'inside'"
                                class="bazis-select__trigger"
                            >
                                <span>
                                    <ng-container
                                        [ngTemplateOutlet]="tplSelectTrigger"
                                    ></ng-container>
                                </span>
                            </div>
                            <input
                                #searchInput
                                class="bazis-control__field"
                                [class.bazis-control__field--focused]="
                                    isFocused || (showList && searchControl.value)
                                "
                                [class.bazis-control__field_opacity]="!searchControl.value"
                                [formControl]="searchControl"
                                [mask]="maskSettings?.mask"
                                [patterns]="maskSettings?.patterns"
                                (focus)="focusField()"
                                (blur)="isFocused = false"
                                [placeholder]="placeholderKey ? t(placeholderKey) : ''"
                            />
                        </div>
                    </div>

                    <bazis-control-search-end
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipSettings]="tooltipSettings"
                        [hasSearchIcon]="hasSearchIcon"
                        [multiple]="multiple"
                        [isDisabled]="isDisabled"
                        [canEraserShow]="
                            hasEraser &&
                            (viewTypeSelected === 'inside' || viewTypeSelected === 'empty')
                        "
                        [searchValue]="searchControl.value"
                        [controlValue]="ngControl.control.value"
                        [iconUp]="iconUp"
                        [iconDown]="iconDown"
                        [otherIcon]="rightIcon"
                        [showList]="showList"
                        (erased)="onClear()"
                        (toggled)="toggleList()"
                        (hideListMobile)="hideListMobile()"
                    ></bazis-control-search-end>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
                    <ng-content select="error"></ng-content>
                </div>

                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ noteKey }}
                </div>

                <ng-content select="note"></ng-content>

                <div
                    #listHint
                    class="bh-popper-z-index"
                    [hidden]="!showList"
                    (click)="clickPopper()"
                >
                    <bazis-infinite-list
                        [searchSettings]="searchSettings"
                        [emptyValue]="emptyValueEntity"
                        [staticOptionsEntities]="staticOptionsEntities"
                        [excludeIds$]="excludeIds$"
                        [emptyListKey]="emptyListKey || 'title.emptySearch'"
                        [search$]="searchValue$"
                    >
                        <ng-template let-item="item">
                            <bazis-multiple-selection-item
                                [item]="item"
                                [infiniteListTpl]="infiniteListTpl"
                                [fieldForId]="searchSettings.fieldForId"
                                [multiple]="multiple"
                                [valuesMap]="valuesMap"
                                (toggled)="
                                    toggleValue(
                                        searchSettings.fieldForId
                                            ? item.$snapshot[searchSettings.fieldForId]?.id
                                            : item.id
                                    )
                                "
                            ></bazis-multiple-selection-item>
                        </ng-template>
                    </bazis-infinite-list>
                </div>
            </div>

            <div
                *ngIf="viewTypeSelected === 'tagsEnd'"
                class="bazis-tags-group bh-margin-top-2x"
            >
                <ng-container [ngTemplateOutlet]="tplTags"></ng-container>
            </div>
        </div>

        <ng-template #tplSelectTrigger>
            <ng-container *ngFor="let value of values.$ | async; let i = index">
                <ng-container *ngIf="!value">{{
                    placeholderKey ? t(placeholderKey) : ''
                }}</ng-container>
                <ng-container
                    *ngIf="searchSettings.entityType !== 'organization.organization_info'"
                >
                    <ng-container *ngIf="value | entity : entityType | async as entity">
                        <bazis-autocomplete-selected-value
                            [infiniteListTpl]="infiniteListTpl"
                            [selectedValueTpl]="selectedValueTpl"
                            [entity]="entity"
                            [valuesLength]="values._.length"
                            [index]="i"
                        ></bazis-autocomplete-selected-value>
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngIf="searchSettings.entityType === 'organization.organization_info'"
                >
                    <ng-container *ngIf="value | organization | async as entity">
                        <bazis-autocomplete-selected-value
                            [infiniteListTpl]="infiniteListTpl"
                            [selectedValueTpl]="selectedValueTpl"
                            [entity]="entity"
                            [valuesLength]="values._.length"
                            [index]="i"
                        ></bazis-autocomplete-selected-value>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>

        <ng-template #tplTags>
            <ng-container *ngFor="let value of data.values">
                <div
                    *ngIf="value | entity : entityType | async as entity"
                    class="bazis-tag"
                >
                    <span>
                        <ng-container
                            *ngIf="infiniteListTpl"
                            [ngTemplateOutlet]="infiniteListTpl"
                            [ngTemplateOutletContext]="{ item: entity }"
                        ></ng-container>
                        <ng-container *ngIf="!infiniteListTpl">{{
                            entity.$snapshot?.name
                        }}</ng-container>
                    </span>
                    <button
                        *ngIf="!isDisabled"
                        (click)="toggleValue(value)"
                    >
                        <bazis-icon
                            [name]="icons.trash"
                            size="xxs"
                        ></bazis-icon>
                    </button>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>
</ng-container>
