import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
    OnInit,
} from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';
import { ValidationErrorMessages } from '@bazis/form/models/form-element.types';
import { tap } from 'rxjs/operators';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { BazisFormService } from '@bazis/form/services/form.service';
import { BazisControlValueAccessor } from '@bazis/form/components/control-value-accessor.component';

@UntilDestroy()
@Component({
    selector: 'bazis-input-symbol',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputSymbolComponent
    extends BazisControlValueAccessor
    implements OnChanges, OnInit
{
    @Input() symbolAmount: number = 1;

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    @Input() type: 'text' | 'number' = 'text';

    // для всплытия состояния
    @Output() allSymbolsEntered = new EventEmitter();

    @ViewChild('group') group: ElementRef;

    fields = new FormArray([]);

    valueChanges$ = this.fields.valueChanges.pipe(
        tap((value) => {
            const str = value.join('');
            this.onChange(str);
            if (str.length === this.symbolAmount) {
                this.allSymbolsEntered.emit();
            }
        }),
    );

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }
        for (let i = 0; i < this.symbolAmount; i++) {
            (this.fields as FormArray).push(new FormControl('', [Validators.required]));
        }
        setTimeout(() => {
            this.setFocusInFieldByIndex(0);
        });
        if (this.ngControl.control.value) {
            this.writeValue(this.ngControl.control.value);
        }
    }

    onInput(index) {
        if (`${this.fields.value[index]}`.length > 1) {
            this.fields.controls[index].setValue(this.fields.value[index][0]);
        }
        if (index < this.symbolAmount - 1 && this.fields.value[index]) {
            this.setFocusInFieldByIndex(index + 1);
        }
    }

    onBackspace(index) {
        if (index > 0) {
            this.setFocusInFieldByIndex(index - 1);
        }
    }

    getElementByIndex(index = 0) {
        return this.group.nativeElement.children[index].children[0];
    }

    setFocusInFieldByIndex(index = 0) {
        this.getElementByIndex(index).focus();
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.fields.disable();
        } else {
            this.fields.enable();
        }
    }

    public writeValue(value: any): void {
        this.fields.controls.forEach((control) => {
            control.setValue('', { emitEvent: false });
        });

        value.split('').forEach((symbol, i) => {
            if (i < this.symbolAmount && this.fields.controls.length > i) {
                this.fields.controls[i].setValue(symbol, { emitEvent: false });
            }
        });
        this.fields.updateValueAndValidity();
    }

    public onFocus(index = 0) {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
        this.isFocused = true;
        const length = this.fields.controls[index].value.length;
        if (length > 0) {
            this.getElementByIndex(index).select(0, length);
        }
    }
}
