import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { BazisControlSelectEndComponent } from '@bazis/form/components/parts/control-select-end.component';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-control-search-end',
    templateUrl: './control-search-end.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisControlSearchEndComponent extends BazisControlSelectEndComponent {
    @Input() hasSearchIcon: boolean = false;

    @Input() canEraserShow: boolean = null;

    @Input() multiple: boolean = null;

    @Input() searchValue = null;

    @Input() controlValue = null;

    @Input() isDisabled: boolean = null;

    @Input() otherIcon: string;

    @Output() erased = new EventEmitter();

    @Output() hideListMobile = new EventEmitter();

    constructor(
        configurationService: BazisConfigurationService,
        public mqSrv: BazisMediaQueryService,
    ) {
        super(configurationService);
    }

    onClear(e) {
        this.erased.emit(e);
    }

    onHideListMobile() {
        this.hideListMobile.emit();
    }
}
