<ng-container *transloco="let t">
    <p *ngIf="errorKey">
        <span
            color="danger"
            *ngIf="!validationErrorMessages || !validationErrorMessages[errorKey]"
        >
            {{ t('form.error.' + errorKey, errorParams) }}
        </span>
        <span
            color="danger"
            *ngIf="validationErrorMessages && validationErrorMessages[errorKey]"
        >
            {{ t(validationErrorMessages[errorKey], errorParams) }}
        </span>
    </p>
    <p *ngIf="!errorKey && error">
        <span color="danger">
            {{ error }}
        </span>
    </p>
    <bazis-icon
        *ngIf="hasIcon"
        [name]="icons.exclamation"
        color="danger"
        size="xs"
    ></bazis-icon>
</ng-container>
