<div
    class="bazis-btn-group bazis-btn-group_h"
    *transloco="let t"
>
    <div
        #btnZoomin
        class="bazis-btn-group__item"
    >
        <bazis-button
            [fill]="fillButton"
            [disabled]="zoomMaxExceeded.$ | async"
            (click)="zoomIn()"
        >
            <bazis-icon
                slot="icon-only"
                name="plus"
            ></bazis-icon>
        </bazis-button>
        <bazis-tooltip
            [reference]="btnZoomin"
            placement="left"
            class="bh-popper-z-index"
            [isStatic]="true"
            [offset]="[-12, 0]"
            [arrow]="false"
            >{{ t('map.tooltip.zoomIn') }}</bazis-tooltip
        >
    </div>
    <div
        #btnZoomout
        class="bazis-btn-group__item"
    >
        <bazis-button
            [fill]="fillButton"
            [disabled]="zoomMinExceeded.$ | async"
            (click)="zoomOut()"
        >
            <bazis-icon
                slot="icon-only"
                name="minus"
            ></bazis-icon>
        </bazis-button>
        <bazis-tooltip
            [reference]="btnZoomout"
            placement="left"
            class="bh-popper-z-index"
            [isStatic]="true"
            [offset]="[-12, 0]"
            [arrow]="false"
            >{{ t('map.tooltip.zoomOut') }}</bazis-tooltip
        >
    </div>
</div>
