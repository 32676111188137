import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BazisInputSymbolComponent } from '@bazis/form/components/base/inputs/input-symbol.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-input-symbol',
    templateUrl: './input-symbol.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSymbolComponent extends BazisInputSymbolComponent {}
