import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'bazis-autocomplete-selected-value',
    templateUrl: './autocomplete-selected-value.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisAutocompleteSelectedValueComponent {
    @Input() infiniteListTpl: TemplateRef<any>;

    @Input() selectedValueTpl: TemplateRef<any>;

    @Input() entity;

    @Input() index: number;

    @Input() valuesLength: number;

    @Input() entityNameField: string;
}
